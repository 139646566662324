import { useState } from 'react'
import CardGlass from '../components/CardGlass'

function BusinessOverview() {
  const [savingsGoal, setSavingsGoal] = useState(60000)
  const [currentBalance, setCurrentBalance] = useState(2000)
  const [isEditable, setIsEditable] = useState(false)
  const [tempSavingsGoal, setTempSavingsGoal] = useState(savingsGoal)
  const [tempCurrentBalance, setTempCurrentBalance] = useState(currentBalance)
  const targetDate = new Date('August 1, 2024')
  const startDate = new Date('April 1, 2023')
  const currentDate = new Date()
  const monthsUntilTarget = Math.round(
    (targetDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24 * 30)
  )
  const monthsSinceStart = Math.round(
    (startDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24 * 30)
  )

  const minMonthlyIncome = Math.floor(
    (savingsGoal - currentBalance) / monthsUntilTarget
  )
  const avgMonthlyIncome = Math.floor(-currentBalance / monthsSinceStart)

  const handleSave = () => {
    setIsEditable(false)
    setSavingsGoal(tempSavingsGoal)
    setCurrentBalance(tempCurrentBalance)
  }

  const handleCancel = () => {
    setIsEditable(false)
    setTempSavingsGoal(savingsGoal)
    setTempCurrentBalance(currentBalance)
  }

  return (
    <div className="">
      <div className="w-100 m-auto grid">
        <div>
          <CardGlass>
            <div className="grid grid-cols-2 w-full gap-2">
              <h4 className="text-white/60 font-bold uppercase text-sm">
                Date:
              </h4>{' '}
              <h4 className="text-white/60 font-bold uppercase text-sm text-right">
                {targetDate.toLocaleDateString('en-AU', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}
              </h4>
              <h4 className="text-white/60 font-bold uppercase text-sm">
                Runway Period:
              </h4>
              <h4 className="text-white/60 font-bold uppercase text-sm text-right">
                12 Months
              </h4>
              <h4 className="text-white/60 font-bold uppercase text-sm">
                Opening Balance:
              </h4>
              <h4 className="text-white/60 font-bold uppercase text-sm text-right">
                $2000
              </h4>
              <h4 className="text-white/60 font-bold uppercase text-sm">
                Months to Go:
              </h4>
              <h4 className="text-white/60 font-bold uppercase text-sm text-right">
                <div>{monthsUntilTarget.toLocaleString()}</div>
              </h4>
            </div>
          </CardGlass>
        </div>
        <CardGlass>
          <div className="grid gap-2 grid-cols-2 w-full">
            <h4 className="text-white/60 font-bold uppercase text-sm">
              Savings Goal:
            </h4>
            <h4
              className="text-white/60 font-bold uppercase text-sm cursor-pointer text-right"
              onClick={() => setIsEditable(true)}
            >
              EDIT
            </h4>
            {isEditable ? (
              <div className="flex gap-2 text-5xl font-light text-white">
                $
                <input
                  type="number"
                  className="text-5xl font-light text-white bg-transparent p-0 m-0 border-b border-white"
                  value={tempSavingsGoal}
                  onChange={(e) => setTempSavingsGoal(e.target.value)}
                />
                <div className="flex gap-2">
                  <button onClick={handleSave}>Save</button>
                  <button onClick={handleCancel}>Cancel</button>
                </div>
              </div>
            ) : (
              <div className="text-5xl font-light text-white">
                {savingsGoal.toLocaleString('en-AU', {
                  style: 'currency',
                  currency: 'AUD',
                  minimumFractionDigits: 0,
                })}
              </div>
            )}
          </div>
        </CardGlass>
        <CardGlass>
          <div className="grid gap-2 grid-cols-2 w-full">
            <h4 className="text-white/60 font-bold uppercase text-sm">
              Current Balance:
            </h4>
            <h4
              className="text-white/60 font-bold uppercase text-sm cursor-pointer text-right"
              onClick={() => setIsEditable(true)}
            >
              EDIT
            </h4>
            {isEditable ? (
              <div className="flex gap-2 text-5xl font-light text-white">
                $
                <input
                  type="number"
                  className="text-5xl font-light text-white bg-transparent p-0 m-0 border-b border-white"
                  value={tempCurrentBalance}
                  onChange={(e) => setTempCurrentBalance(e.target.value)}
                />
                <div className="flex gap-2">
                  <button onClick={handleSave}>Save</button>
                  <button onClick={handleCancel}>Cancel</button>
                </div>
              </div>
            ) : (
              <div className="text-5xl font-light text-white">
                {currentBalance.toLocaleString('en-AU', {
                  style: 'currency',
                  currency: 'AUD',
                  minimumFractionDigits: 0,
                })}
              </div>
            )}
          </div>
        </CardGlass>
        <div className="grid grid-cols-2 gap-4">
          <CardGlass>
            <div className="grid gap-2">
              <h4 className="text-white/60 font-bold uppercase text-sm">
                Min. Monthly Income
              </h4>
              <h1 className="text-5xl font-light text-white">
                {minMonthlyIncome.toLocaleString('en-AU', {
                  style: 'currency',
                  currency: 'AUD',
                  minimumFractionDigits: 0,
                })}
              </h1>
            </div>
          </CardGlass>
          <CardGlass>
            <div className="grid gap-2">
              <h4 className="text-white/60 font-bold uppercase text-sm">
                Avg. Monthly Income
              </h4>
              <h1 className="text-5xl font-light text-white">
                {avgMonthlyIncome.toLocaleString('en-AU', {
                  style: 'currency',
                  currency: 'AUD',
                  minimumFractionDigits: 0,
                })}
              </h1>
            </div>
          </CardGlass>
        </div>
      </div>
    </div>
  )
}

export default BusinessOverview
