import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import {toast} from 'react-toastify'
import { FaArrowRight } from 'react-icons/fa'

function LogIn() {
  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const { email, password } = formData

  const navigate = useNavigate()

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }
  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      const auth = getAuth()
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )

      if (userCredential.user) {
        navigate('/business-overview')
      }
    } catch (error) {
      toast.error('Unable to login')
    }
  }

  return (
    <div className="">
      <div className="mx-auto shadow-xl rounded-xl p-20 bg-white max-w-xl">
        <header>
          <h1 className="">Welcome Back!</h1>
        </header>
        <form onSubmit={onSubmit}>
          <div>
            <input
              type="email"
              className="border p-3 mb-3"
              placeholder="Email"
              id="email"
              value={email}
              onChange={onChange}
            />
            <input
              type={showPassword ? 'text' : 'password'}
              className="border p-3 mb-3"
              placeholder="Password"
              id="password"
              value={password}
              onChange={onChange}
            />
           
          </div>
         

          <div>
            <button>
              Log In <FaArrowRight />
            </button>
          </div>
        </form>

      </div>
    </div>
  )
}

export default LogIn
