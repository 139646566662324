import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import CRM from './pages/CRM'
import BookingManager from './pages/BookingManager'
import BusinessOverview from './pages/BusinessOverview'
import LogIn from './pages/LogIn'
import RecipeDatabase from './pages/RecipeDatabase'

import Navbar from './components/Navbar'

import PrivateRoute from './components/PrivateRoute'
import LogOut from './components/LogOut'

// ACCESS FIREBASE
//const db = firebase.firestore()
//const userID = 'Mp8lPhiDFXX0wPxkLxgJ' // This is hardcoded ---- need to be dynamic
//const firebaseDoc = db.collection('SupplyKit').doc('Events')

function App() {
  return (
    <>
      <Router>
        <div className="w-full mx-auto h-screen flex flex-col relative">
          <div className="grow bg-gradient-to-b from-fuchsia-800 to-orange-400 p-4 lg:p-10">
          <LogOut />

           
            <Routes>
              <Route exact path="/" element={<LogIn />}></Route>
              
              <Route path="/booking-manager" element={<PrivateRoute />} >
                <Route path="/booking-manager" element={<BookingManager />} />
              </Route>

              <Route path="/business-overview" element={<PrivateRoute />}>
                <Route
                  path="/business-overview"
                  element={<BusinessOverview />}
                />
              </Route>
              <Route path="/recipes" element={<PrivateRoute />} >
              <Route path="/recipes" element={<RecipeDatabase />} />
              </Route>


              <Route path="/crm" element={<PrivateRoute />} >
              <Route path="/crm" element={<CRM />} />
              </Route>
            </Routes>
          </div>

          <Navbar />
        </div>
      </Router>

      <ToastContainer />
    </>
  )
}

export default App
