import React from 'react'
import CardGlass from './CardGlass'
import { FaTag } from 'react-icons/fa'
import { FaMapMarkerAlt } from 'react-icons/fa'

import { FaGlassMartiniAlt } from 'react-icons/fa'

import { FaQrcode } from 'react-icons/fa'

import { FaPrint } from 'react-icons/fa'

import { FaBox } from 'react-icons/fa'

import { FaTruck } from 'react-icons/fa'
import Divider from './Divider'

function BookingCard() {
  return (
    <CardGlass>
      <div className="grid grid-cols-2 text-white">
        <h2 className="col-span-full text-xl font-bold">Name</h2>

        <Divider />

        <div>Quantity</div>
        <div>
          $Total Amount
          <br />
          Balance Owing
        </div>

        <Divider />

        <div className="col-span-full flex gap-8 text-2xl">
          <FaTag />
          <FaMapMarkerAlt />
          <FaGlassMartiniAlt />
          <FaQrcode />
        </div>

        <Divider />
        <div className="col-span-full flex gap-8 text-2xl">
          <FaPrint />
          <FaBox />
          <FaTruck />
        </div>

        <Divider />
        <div>
          <h4 className="text-white/60 font-bold uppercase text-sm">
            Event Date:
          </h4>
          <h4 className="text-white font-medium text-lg ">24 May 2023</h4>
        </div>
        <div>
          <h4 className="text-white/60 font-bold uppercase text-sm">
            Dispatch By:
          </h4>
          <h4 className="text-white font-medium text-lg ">4 May 2023</h4>
        </div>
      </div>
    </CardGlass>
  )
}

export default BookingCard
