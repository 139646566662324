import React from 'react'
import CardGlass from '../components/CardGlass'
import Divider from '../components/Divider'

function RecipeDatabase() {
  return (
    
    <CardGlass>
    <div className="grid grid-cols-1 text-white">
      <h2 className="col-span-full text-xl font-bold">Name</h2>

      <Divider />

      <div>Alc Vol</div>
      <div>Description</div>
      
    </div>
  </CardGlass>  )
}

export default RecipeDatabase
