function CardGlass({ children }) {
    
  return (
    <div className='my-5 pt-4 px-4 pb-6  bg-gradient-to-br from-white/0 to-white/30 rounded-xl shadow-lg space-x-4 border-2 border-white/30 '>
       
        {children}
    </div>





  )
}

export default CardGlass