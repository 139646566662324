import React from 'react'
import BookingCard from '../components/BookingCard'

function BookingManager() {
  return (
    <>
    <BookingCard />
    </>
    )
}

export default BookingManager