import { useNavigate, useLocation } from 'react-router-dom'
import { FaUser } from 'react-icons/fa'
import { FaCocktail } from 'react-icons/fa'
import { FaQuestionCircle } from 'react-icons/fa'


function Navbar() {

    const navigate = useNavigate()
    const location = useLocation()

    const pathMatchRoute = (route) => {
        if(route === location.pathname) {
            return true
        }
    }



  return (
    <footer className="navbar sticky bottom-0 left-0 right-0 bg-slate-900 py-3">
      <nav className="navbarNav">
        <ul className='navbarListItems'>
        <li className='navbarListItem' onClick={() => navigate('/crm')}>
            <FaUser fill={pathMatchRoute('/crm') ? 'rgb(251,146,60)' : '#FFF7ED'} className='mb-2'/>
            <p className={pathMatchRoute('/crm')
            ?  'text-orange-400'
            : 'text-[#FFF7ED]'
            }
            >CRM</p>
          </li>
          <li className='navbarListItem' onClick={() => navigate('/booking-manager')}>
            <FaUser fill={pathMatchRoute('/booking-manager') ? 'green' : '#FFF7ED'} className='mb-2'/>
            <p className={pathMatchRoute('/booking-manager')
            ?  'text-orange-400'
            : 'text-[#FFF7ED]'
            }
            >Bookings</p>
          </li>
          <li className='navbarListItem'  onClick={() => navigate('/recipes')}>
          <FaCocktail fill={pathMatchRoute('/recipes') ? 'green' : '#FFF7ED'} className='mb-2'/>

          <p className={pathMatchRoute('/recipes')
            ?  'text-orange-400'
            : 'text-[#FFF7ED]'
            }
            >Cocktails</p>
          </li>
          <li className='navbarListItem' onClick={() => navigate('/business-overview')}>
          <FaQuestionCircle fill={pathMatchRoute('/business-overview') ? 'green' : '#FFF7ED'} className='mb-2'/>

          <p className={pathMatchRoute('/business-overview')
            ?  'text-orange-400'
            : 'text-[#FFF7ED]'
            }
            >Overview</p>
          </li>
         
        </ul>
      </nav>
    </footer>
  )
}

export default Navbar
