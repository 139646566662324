import { useState, useEffect } from 'react'

import { getAuth, updateProfile } from 'firebase/auth'
import { updateDoc, doc } from 'firebase/firestore'
import { db } from '../firebase.config'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify'


function LogOut() {

    const auth = getAuth()
    const navigate = useNavigate()


  const onLogout = () => {
    auth.signOut()
    console.log('test')
    navigate('/')

  }



  return (
    <div>
         <button type="button" onClick={onLogout} className="text-white">
              Log Out
            </button>

    </div>
  )
}

export default LogOut